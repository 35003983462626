import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import store from "store";

// Material-UI components
import { ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

// Material-UI icons
import CloseIcon from "@material-ui/icons/Close";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ImageIcon from "@material-ui/icons/Image";

// Material-UI lab components
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// Theme
import theme from "../../assets/v3/theme";

// APIs
import { getRegistrationFormConfig, uploadAvatar } from "../../apis/UserService";

// Hooks
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";

// Components
import FileUploader from "../../components/FileInput";
import PhotoCapture from "../../components/CapturePhoto";
import { useStyles, PageTitle } from ".";
import { hostnamesMap } from "../../components/LogoURLMap";

const AvatarForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const instituteId = hostnamesMap[window.location.hostname]
  const sid = store.get("user")._id;
  const page = "avatar";
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [l2, e2, d2, f2, r2] = useFetch(uploadAvatar);
  const [uploadMode, setUploadMode] = useState("capture");
  const { openSnackbar } = useSnackbar();
  const payload = d1 ? d1.payload : null;
  const details = d1 ? d1.details : {};
  const next = payload ? payload.next : null;
  const previous = payload ? payload.previous : null;
  const [avatarUrl, setAvatarUrl] = useState(details.avatar || null);

  const formatRouteName = (route) => {
    if (!route) return "";
    return route
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  
  const uploadOptions = {
    maxSize: 2 * 1024 * 1024, // 2MB
    minWidth: 200,
    minHeight: 200,
    maxWidth: 1000,
    maxHeight: 1000,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    f1({
      instituteId,
      page,
      sid,
    });

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      console.log("Registration Form Config:", d1);
      setAvatarUrl(details.avatar);
    }
    if (e1) {
      console.error("Failed to fetch registration form config:", e1);
      openSnackbar("Failed to fetch registration form config", "error");
    }
  }, [d1, e1]);

  const handleRemoveAvatar = () => {
    f2({ sid, avatar: null });
  };

  useEffect(() => {
    if (d2) {
      if (d2.data.status === "SUCCESS") {
        setAvatarUrl(null);
      } else {
        openSnackbar("Failed to remove avatar", "error");
      }
    }
    if (e2) {
      openSnackbar("Failed to update avatar", "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  const handleUpload = ({ avatar }) => {
    return uploadAvatar({ sid, avatar });
  };

  const handleFileUpload = (files) => {
    return uploadAvatar({ sid, avatar: files[0].file });
  };

  const handlePhotoUploadSuccess = (response) => {
    if (response.data.status === "SUCCESS") {
      f1({
        instituteId,
        page,
        sid,
      });
    }
  };

  const handleFileUploadSuccess = (response) => {
    if (response.data.status === "SUCCESS") {
      f1({
        instituteId,
        page,
        sid,
      });
    }
  };

  if (l1) {
    return <p>Loading...</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Photo" next={formatRouteName(next)} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={400}
        margin="auto"
        padding={2}
        height="90vh"
      >
        <Box width="100%">
          {avatarUrl ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <img src={avatarUrl} alt="avatar" style={{ height: "40vh" }} />
              <IconButton
                onClick={handleRemoveAvatar}
                color="secondary"
                disabled={l2}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : null}
          {!avatarUrl ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
              >
                <ToggleButtonGroup
                  value={uploadMode}
                  exclusive
                  onChange={(_, newMode) => setUploadMode(newMode)}
                  aria-label="upload mode"
                >
                  <ToggleButton value="capture" aria-label="capture">
                    <CameraAltIcon />
                  </ToggleButton>
                  <ToggleButton value="file-upload" aria-label="upload">
                    <ImageIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <br />
              {uploadMode === "capture" ? (
                <PhotoCapture
                  handleUpload={handleUpload}
                  onPhotoUploadSuccess={handlePhotoUploadSuccess}
                  options={uploadOptions}
                  height={1062 * 0.4}
                  width={826 * 0.4}
                  captureBtnText={"Capture Student Photo"}
                  mainText={"Please Capture Student Photo"}
                />
              ) : (
                <Box marginTop={3}>
                  <FileUploader
                    handleUpload={handleFileUpload}
                    onFileUploadSuccess={handleFileUploadSuccess}
                    options={{
                      maxSize: 1 * 1024 * 1024,
                      minWidth: 200,
                      minHeight: 200,
                      maxWidth: 1000,
                      maxHeight: 1000,
                    }}
                    description="Upload Student Photo"
                    info="(The photo must be uploaded in jpg, jpeg or png format with file size not exceeding 1MB)"
                  />
                </Box>
              )}
            </>
          ) : null}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexGrow={1}
          width="100%"
        >
          <Button
            variant="outlined"
            onClick={() => {
              if (previous) {
                history.push(`/${previous}`);
              }
            }}
            className={classes.blueGradientBtn2}
            color="secondary"
          >
            Previous
          </Button>
          <Button
            variant="contained"
            className={classes.blueGradientBtn}
            onClick={() => {
              if(!avatarUrl) {
                openSnackbar("Please upload STUDENT photo", "warning", false);
                return;
              }
              if (next) {
                history.push(`/${next}`);
              }
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AvatarForm;
